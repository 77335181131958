import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCrCwVAunqNOgBL-kg_Euo7UblgT9mg7SI",
    authDomain: "landing-page-9f952.firebaseapp.com",
    projectId: "landing-page-9f952",
    storageBucket: "landing-page-9f952.appspot.com",
    messagingSenderId: "226536354295",
    appId: "1:226536354295:web:eea1953c495ec8d5f57ef2",
    measurementId: "G-EG4ZHNN4NP"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore()

export { db as db }