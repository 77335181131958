import React, { useEffect, useState } from 'react';

import { doc, addDoc, collection, setDoc, Timestamp } from "firebase/firestore";
import { db } from "./static/firebaseConfig"

import Pic from './Frame 14.png';
import Stanford from './static/stanford.png';
import Sequoia from './static/sequoia.png';
import Stripe from './static/stripe.png';
import Logo from './static/logo.png';
import './App.css';

function App() {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")

  const handleSubmit = async () => {
    console.log(email)

    setLoading(true);
    await setDoc(doc(db, 'submissions', email), {
      "timestamp": Timestamp.now(),
      "email": email
    }).then(() => {
      setSubmitted(true);
      setLoading(false);
    });
  }

  return (
    <>
      <div className='header'>
        <img className="logoShot" src={Logo} />
        <p className='logoText'>Why You Should Join</p>
      </div>
      <div className="App">
        <div className="textColumn">
          <p className='titleText'>Hear <b>first</b> about <b>breakout</b> startups worth joining - for free.</p>
          {/* <p className='titleText'>Hear <b>early</b> about breakout startups worth joining - for free.</p> */}
          <p className='subTitleText'>We collect inside information from <b>Sequoia</b>, <b>a16z</b>, and top VCs about fast-growing, <b>breakout</b> startups. We pick the <b>best one</b> each month and bring it to you, <b>before anyone else.</b></p>
          <div className="inputContainer">
            {submitted ?
              <div className='successBox'>
                <p className='successText'>
                  Success! Thanks for subscribing!
                </p>
              </div>
              :
              <>
                <iframe
                  className="iframeClass"
                  src="https://whyyoushouldjoin.substack.com/embed"
                  width="1000"
                  scrolling="no"
                  style={{
                    border: "1px solid #f3f2ed",
                    background: "#f3f2ed"
                  }}>
                </iframe>
                {/* <input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Enter your email'
                  disabled={loading}
                  input type="email"
                />
                <div className='submit' onClick={handleSubmit}>Sign Up</div> */}
              </>
            }
          </div>
          <div className='socialContainer'>
            <img className="socialShotFirst" src={Stripe} />
            <img className="socialShot" src={Sequoia} />
            <img className="socialShot" src={Stanford} />
            <p className='socialText'>Join 3000+ ambitious readers from Stripe, Sequoia, and Stanford</p>
          </div>
        </div>
        <div className="imageColumn">
          <img className="imageShot" src={Pic} />
        </div>
      </div>
      <div className='footer' />
    </>
  );
}


export default App;
